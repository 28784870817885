<template>
    <div :class="this.routesHideHeader.includes(this.$route.name) ? '' : 'header'" id="header">
      <b-navbar toggleable="lg" sticky fixed type="dark" variant="info" :id="this.routesHideHeader.includes(this.$route.name) ? 'terms-nav' : 'main-nav'" style="z-index:1000" :class="{'navbarOpen' : show}">
        <b-navbar-brand class="ml-lg-5" href="/#header"><img src="@/assets/logo.png" alt="OnePlay" @click="handleNavClick"></b-navbar-brand>
        <b-navbar-nav class="mobile-only mt-0" >
        </b-navbar-nav>
        <b-navbar-toggle target="nav-collapse" :ref="'nav-toggler'" @click="toggleNavbar"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="/#planes" class="text-nav ml-1" @click="handleNavClick">Planes</b-nav-item>
            <b-nav-item href="/#contenidos" class="text-nav ml-1" @click="handleNavClick">Contenidos</b-nav-item>
            <b-nav-item href="https://webtv.oneplayperu.tv" class="text-nav ml-1" @click="handleNavClick">Ver TV</b-nav-item>
          <b-button class="btn-green" id="help-quest" v-b-modal.helpmodal style="font-size: 1rem !important;">¿Necesitas ayuda en tu compra?</b-button> 
            <!--<b-nav-item href="#" class="text-nav ml-4" @click="handleNavClick">¿Cómo funciona?</b-nav-item>-->
            <b-nav-item class="mobile-only ml-1" @click="handleButtonLogin()">
              <b-button class="btn-login">Iniciar Sesión</b-button>
            </b-nav-item>
            <!-- <b-nav-item class="mobile-only ml-4">
              <b-button class="btn-sale" @click="handleButtonSale()"><b>Obtén tu MES GRATIS</b></b-button> 
            </b-nav-item> -->
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto desktop-only">
            <b-nav-item><b-button class="btn-green" v-b-modal.helpmodal>¿Necesitas ayuda en tu compra?</b-button></b-nav-item>
          </b-navbar-nav>
          <!-- <b-navbar-nav class="ml-2 desktop-only">
            <b-nav-item><b-button class="btn-sale" @click="handleButtonSale()"><b>Obtén tu MES GRATIS</b></b-button></b-nav-item>
          </b-navbar-nav> -->
          <b-navbar-nav class="desktop-only">
            <b-nav-item><b-button class="btn-login" @click="handleButtonLogin()">Iniciar Sesión</b-button></b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
      <b-col cols="12" class="sec-1 pt-custom" :class="this.routesHideHeader.includes(this.$route.name) ? 'd-none' : ''">
        <b-row class="mx-lg-5 mt-lg-0">
          <b-col lg="7" sm="12">
            <div class="img-tv-title" id="image-space">
              <p><br></p>
            </div>
            <div class="img-tv mx-auto mt-lg-2">
              
            </div>
          </b-col>
          <b-col lg="5" sm="12" class="mt-sm-4 pt-lg-3" style="text-align:left">
            <div class="text-sec-1 mx-auto">
              <b class="title-bold">IPTV Prepago, <br> Vive la experiencia a un click</b> <br><br> Desde
              tu TV Box o App Móvil Controla <br> lo que gastas y paga cuando <br> quieras.
              <div class="spacer">&nbsp;</div>
            </div>
            <div class="mx-auto text-movil">
              <p>Disfruta de los mejores <b>contenidos <br> digitales </b> en múltiples dispositivos</p>
            </div>
            <div class="text-sec-1-mini mt-sm-0">
              <b><span class="promo-text plans">Conoce nuestros planes prepago desde<br></span></b>
              <b><span class="promo-text cost"><b style="font-size: 9vh;">S/25.00</b>Mensual</span></b>
            </div>
            <div class="mobile-only mt-4">
              <a href="https://pago.oneplayperu.tv/index.php?route=product/category&path=59" class="onp-btn-b" @click="handleButtonPlans">VER PLANES</a>
            </div>
            <div class="desktop-only mt-sm-4">
              <a href="https://pago.oneplayperu.tv/index.php?route=product/category&path=59" class="onp-btn-b" @click="handleButtonPlans">VER PLANES</a>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-modal id="helpmodal" size="lg" @hidden="resetModal" hide-footer no-close-on-backdrop no-close-on-esc>
        <transition name="fade" mode="out-in">
          <div v-if="modalstep">
            <p class="text-center">
              <img src="~@/assets/question-mark.png" alt="">
            </p>
            <p class="text-center h4">
              Deja tus datos acá y te contactaremos a la brevedad
            </p>
            <div class="text-center">
              <b-form @submit.prevent="sendHelp" class="mt-5 text-left" ref="formhelp" autocomplete="off">
                <b-form-row>
                  <b-col md="7" lg="6" offset-lg="1" class="pr-md-4">
                    <label for="nomape">Nombre Apellido</label>
                    <b-form-input
                      id="nomape"
                      required
                      name="name"
                      placeholder="Ej: María Lira"
                      pattern="([^\s][\D\s]+)"
                      oninvalid="setCustomValidity('Ingrese un nombre válido')"
                      oninput="(function(e){e.setCustomValidity(''); return !e.validity.valid && e.setCustomValidity(' ')})(this)"
                      minlength="5"
                    ></b-form-input>
                  </b-col>
                  <b-col md="5" lg="4" class="mt-4 mt-md-0">
                    <label for="fono">Teléfono</label>
                    <b-input-group prepend="+51">
                      <b-form-input
                        id="fono"
                        type="number"
                        required
                        name="telephone"
                        placeholder="Ej: 987677897"
                        min="900000000"
                        max="999999999"
                        oninvalid="setCustomValidity('Ingrese un número celular válido')"
                        oninput="(function(e){e.setCustomValidity(''); return !e.validity.valid && e.setCustomValidity(' ')})(this)"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-form-row>
                <br>
                <div class="form-group mt-5 text-center">
                  <b-overlay
                    :show="sendingMail"
                    rounded
                    opacity="0.6"
                    spinner-small
                    class="d-inline-block"
                  >
                  <b-button type="submit" class="btn-lightblue px-5" :disabled="sendingMail">Enviar</b-button>
                  </b-overlay>
                </div>
              </b-form>
            </div>
          </div>
          <div v-else class="text-center">
            <p>
              <img src="~@/assets/smile.png" alt="">
            </p>
            <p class="h4">
              Gracias por preferirnos, te contactaremos en breve
            </p>
            <br>
            <p class="mt-5">
              <b-button class="btn-lightblue px-5" @click="$bvModal.hide('helpmodal')">Cerrar</b-button>
            </p>
          </div>
        </transition>
      </b-modal>
    </div>
</template>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
<script>

export default {
  data() {
    return {
      show: false,
      modalstep: true,
      sendingMail: false,
      routesHideHeader: ['terms','politics','dispatch','about','faq']
    }
  },
  methods:{
    toggleNavbar() {
      this.show = !this.show;
    },
    handleNavClick() {
      /* eslint-disable */
      console.log(this.$refs['nav-toggler'].$el.classList.contains('not-collapsed'));
      /* eslint-enable */
      const toggler = this.$refs['nav-toggler'].$el;
      if(toggler.classList.contains('not-collapsed')){
        setTimeout(() => {
          toggler.click();  
        }, 200);
      }
    },
    handleButtonSale(){
      window.location.href =  'https://pago.oneplayperu.tv/index.php?route=product/category&path=59';
    },
    handleButtonLogin(){
      window.location.href = 'https://pago.oneplayperu.tv/index.php?route=account/login';
    },
    handleButtonPlans(){
      window.location.href = 'https://pago.oneplayperu.tv/index.php?route=product/category&path=59';
    },
    resetModal(){
      this.modalstep = true;
    },
    async sendHelp(){

      this.sendingMail = true;

      const formData = new FormData(this.$refs['formhelp']);
      const response = await fetch('https://iptvpago.oneplayperu.tv/api/v1/help-mail',{method:'POST',body:formData});

      this.sendingMail = false;

      if (!response.ok) {
        alert("Error en el envío de la información, contacte al administrador");
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      
      const result = await response.json();

      ('error' in result && !result.error) ? this.modalstep = false : alert("Error en el envío de la información, contacte al administrador");
    }
  } 
}
</script>