<template>
  <div>
    <div class="sec-2 mt-3" id="planes">
      <div class="mx-auto">
        <div class="text-sec-2 title">
          <b>Planes Prepago</b>
        </div>
        <div class="text-sec-2-desc">
          No pagues demás, paga lo justo, por el tiempo que quieras
        </div>
        <b-container class="mt-4">
          <b-row class="justify-content-lg-center">
            <b-col lg="4" sm="12">
              <div class="basic-plan ml-lg-3 mx-auto"></div>
              <div class="text-sec-2-mini mx-lg-auto mx-auto">Ingresa el código <b>1MESFREE</b> para obtener tu <b>Primer mes GRATIS</b></div>
              <br>
              <div class="desktop-only">
                <a href="https://pago.oneplayperu.tv/index.php?route=product/product&path=59&product_id=54" class="onp-btn-b ml-lg-3" @click="handleIndivPlanButton">COMPRAR PLAN</a>
              </div>
              <div class="mobile-only mt-4 mb-5">
                <a href="https://pago.oneplayperu.tv/index.php?route=product/product&path=59&product_id=54" class="onp-btn-b-2 ml-lg-3" @click="handleIndivPlanButton">COMPRAR PLAN</a>
              </div>
            </b-col>
            <b-col lg="4" sm="12">
              <div class="family-plan ml-lg-2 mx-lg-0 mx-auto"></div>
              <div class="text-sec-2-mini mx-lg-auto mx-auto">Ingresa el código <b>1MESFREE</b> para obtener tu <b>Primer mes GRATIS</b></div>
              <br>
              <div class="desktop-only">
                <a href="https://pago.oneplayperu.tv/index.php?route=product/product&path=59&product_id=55" class="onp-btn-b ml-lg-3" @click="handleDuoPlanButton">COMPRAR PLAN</a>
              </div>
              <div class="mobile-only mt-4 mb-5">
                <a href="https://pago.oneplayperu.tv/index.php?route=product/product&path=59&product_id=55" class="onp-btn-b-2 ml-lg-3" @click="handleDuoPlanButton">COMPRAR PLAN</a>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <br>
        <b-container class="mt-4" id="white">
          <div class="whitebox mx-auto">
            <b-row class="mx-1">
              <b-col lg="2" sm="12">
                <div class="desktop-only mt-4 pt-lg-1 mb-4 pb-lg-1">
                  <div class="stb-img ml-lg-4"></div>
                </div>
                <div class="mobile-only">
                  <div class="stb-img ml-lg-4 mx-auto"></div>
                </div>
              </b-col>
              <b-col class="col-lg-3-custom" cols="6">
                <div class="mt-lg-4 pt-lg-1 text-sec-2-mini bold t-custom-1 mt-3 ml-2 ml-lg-0">
                  <span class="desktop-only">TV Box Oneplay</span>
                  <span class="mobile-only" style="font-size:.8rem">TV Box Oneplay</span>
                  <span class="desktop-only price-text">S/150</span>
                  <span class="simple-text desktop-only">Se paga sólo una vez</span>
                  <span class="mobile-only" style="font-size:1.3rem">S/150</span>
                  <span class="simple-text mobile-only" style="font-size:.7rem">Se paga sólo una vez</span>
                </div>
              </b-col>
              <b-col lg="2" cols="6">
                <div class="mt-lg-4 pt-lg-1 mt-3">
                  <div class="mid-text">
                    SIN COSTO DE MANTENCIÓN
                  </div>
                </div>
              </b-col>
              <b-col lg="5" cols="12" class="mx-auto mx-lg-0">
                <b-row>
                  <b-col lg="2" cols="2" class="mt-lg-2 mt-4 pt-2 pt-lg-0">
                    <img src="~@/assets/hdmi.png" class="img-hdmi mt-lg-4" height="50px" alt="">
                  </b-col>
                  <b-col lg="10" cols="10">
                    <div class="text-sec-2-mini t-custom-1 mt-lg-4 mt-4 ml-2 ml-lg-0">
                      <span class="desktop-only"><b>AUTOINSTALABLE <br>Sin técnicos, ni demoras.</b></span>
                      <span class="desktop-only">TV Box no incluye internet <br> Sólo necesitas 5 mb de internet</span>
                      <span class="mobile-only"><b>AUTOINSTALABLE <div class="w-100"></div> Sin técnicos, ni demoras.</b></span>
                      <span class="mobile-only" style="font-size:.7rem">TV Box no incluye internet <br> Sólo necesitas 5 mb de internet</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <b-container class="mt-4" id="gray-mobile">
          <div class="whitebox-gray mx-auto">
            <b-col>
              <div class="mobile-only">
                  <div class="stb-img ml-lg-4 mx-auto"></div>
              </div>
              <b-row class="mx-1">
                <b-col class="gray-box">
                  <div class="mt-lg-1 pt-lg-1 bold t-custom-1 mt-0 ml-2 ml-lg-0">
                    <span class="mobile-only only-1 only-11" style="font-size:1.2rem">TV Box Oneplay</span>
                    <span class="mobile-only only-1 only-12" style="font-size:1.6rem">S/150</span>
                  </div>
                </b-col>
                <b-col class="gray-box">
                    <div class="t-custom-1 mt-lg-4 mt-0 ml-2 ml-lg-6">
                      <span class="mobile-only sin"> Sin costo de mantenimiento,</span>
                      <span class="mobile-only sin">se paga solo una vez.</span>
                    </div>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-container>
        <b-container class="info-sect mt-lg-4" id="remember-desktop">
          <div class="mx-lg-3">
            <b-row class="mx-lg-auto mb-lg-0 mb-4 mt-lg-0 mt-4">
              <div class="text-sec-2-info t-custom-1 info">
                <div class="info-remember-1">
                <b-icon icon="info-circle"></b-icon>
                </div>
                <div class="info-remember-2">
                    Recuerda ingresar tu <b><span style="color:#08B051">Código de Referido</span></b>. Si no tienes código a la hora de comprar tu plan, <br> digita alguno de estos: <b>ONEPLAYPERU,</b> <b>ONEPLAY1,</b> o <b>ONEPLAY2</b> 
                </div>
              </div>
            </b-row>
          </div>
        </b-container>

        <b-container class="info-sect mt-lg-4" id="remember-mobile">
          <div class="mx-lg-3">
            <b-row class="mx-lg-auto mb-lg-0 mb-4 mt-lg-0 mt-4">
              <div class="text-sec-2-info t-custom-1 info">
                <div class="info-remember-1">
                <b-icon icon="info-circle"></b-icon>
                </div>
                <div class="info-remember-2">
                    Recuerda ingresar tu <b><span style="color:#08B051">Código de Referido</span></b>. Si no tienes código a <br> la hora de comprar tu plan, digita alguno de estos: <br> <b>ONEPLAYPERU,</b> <b>ONEPLAY1,</b> o <b>ONEPLAY2</b> 
                </div>
              </div>
            </b-row>
          </div>
        </b-container>

      </div>
    </div>
    <br><br>
    <div class="dispo-sect mt-lg-5">
      <div class="mx-auto pt-lg-5">
        <div class="text-sec-3 pt-lg-0 pt-3">
          <span class="title"><b>Paquetes Premium</b></span>
        </div>
        <b-container class="mt-0 pt-0">
          <b-row class="justify-content-lg-center pt-lg-0">
            <b-col lg="5" sm="12">
              <div class="azteca-pack ml-lg-0 mx-auto"></div>
      
              <div class="desktop-only">
                <!-- @click="handleIndivPlanButton" -->
                <button class="onp-btn-b-pack ml-lg-3">COMPRAR PAQUETE</button>
              </div>
              <div class="mobile-only mt-3 mb-5">
                <button class="onp-btn-b-2 ml-lg-3">COMPRAR PAQUETE</button>
              </div>
            </b-col>
            <b-col lg="5" sm="12">
              <div class="venus-pack ml-lg-0 mx-auto"></div>
              
              <div class="desktop-only">
                <!-- @click="handleDuoPlanButton" -->
                <button class="onp-btn-b-pack ml-lg-3">COMPRAR PAQUETE</button>
              </div>
              <div class="mobile-only mt-4 mb-5">
                <button class="onp-btn-b-2 ml-lg-3">COMPRAR PAQUETE</button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    
    <div class="gplay-sect" id="gplay-sect-desktop">
      <b-container class="mx-auto">
        <b-row class="justify-content-center pt-lg-5">
          <b-col lg="5" sm="12">
            <div class="phone-img mt-5"></div>
          </b-col>
          <b-col lg="5" sm="12">
            <div class="text-sec-4 mt-lg-5 pt-lg-5 mt-4" style="text-align: left;">
              <span class="title">Televisión en tu dispositivo móvil</span>
            </div>
            <div class="text-sec-4 mt-lg-3 mx-lg-0 mx-5" style="text-align: left;">
              Puedes descargar nuestra app móvil desde la PlayStore
            </div>
            <b-row class="mt-lg-4">
              <b-col lg="1" cols="2">
                <a href="https://play.google.com/store/apps/details?id=tv.trapemn.oneplayperu" target="_blank">
                  <img src="~@/assets/app_icn.png" height="50px" width="50px">
                </a>
              </b-col>
              <b-col lg="7" cols="7">
                <a href="https://play.google.com/store/apps/details?id=tv.trapemn.oneplayperu" target="_blank">
                  <img src="~@/assets/disponible-en-google-play-badge.png" height="50px" width="180px" alt="">
                </a>
              </b-col>
            </b-row>
            
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mx-auto gplay-sect-foot">
        <b-row class="justify-content-center pt-lg-0">
          <b-col lg="5" sm="12">
            <div class="pt-lg-5">
              <div class="foot-disp-1">
                <b>Dispositivos Compatibles</b>
                <p>Nuestra aplicación se encuentra disponible <br> para estos dispositivos.</p>
              </div>
            </div>
          </b-col>
          <b-col lg="5" sm="12">
            <div class="foot-disp-2">
              <div class="text-sec-4 mt-lg-5">
                <b>Móvil</b>
                <p>- Teléfonos Android <br> (Versión 7.0 en adelante)</p>
              </div>
              <div class="text-sec-4 mt-lg-5">
                <b>TV</b>
                <p>- Tv Box Oneplay <br> - Android TV <br> (Versión 7.0 en adelante) </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="gplay-sect" id="gplay-sect-mobile">
      <b-container class="mx-auto">
        
          <b-col lg="5" sm="12">
            <div class="phone-img mt-5"></div>
            <div class="mobile-dispt">
              <div class="text-sec-4">
                <span class="title">Televisión en tu <br> dispositivo móvil</span>
              </div>
              <div class="text-sec-4">
                <p class="text1 size-text">Puedes descargar nuestra app móvil desde la PlayStore</p>
              </div>
              <div class="text-sec-4">
                <img src="~@/assets/app_icn.png" height="33px" width="33px">&nbsp;
                <img src="~@/assets/disponible-en-google-play-badge.png" height="33px" width="125px" alt="">
              </div>
              
              <div class="foot-disp-1 text-sec-4">
                <b class="size-text">Dispositivos Compatibles</b>
                <p class="size-text">Nuestra aplicación se encuentra<br> disponible  para estos dispositivos.</p>
              </div>
            
              <div class="text-sec-4 mt-lg-5">
                <b class="size-text">TV</b>
                <p class="size-text">- Tv Box Oneplay <br> - Android TV (Versión 7.0 en adelante) </p>
              </div>

              <div class="text-sec-4 mt-lg-5">
                <b class="size-text">Móvil</b>
                <p class="size-text">- Teléfonos Android (Versión 7.0 en adelante)</p>
              </div>
              
            </div>
          </b-col>
      </b-container>
    </div>
    
    <div class="content-sect mt-lg-4" id="contenidos">
      <b-container class="mx-auto" id="content-desktop-all">
        <div class="text-sec-5 mt-4 mt-lg-0" >
          <span class="title">Disfruta contenido Regional, Internacional, Entretenimiento y mucho más</span>
        </div>
      </b-container>
      <b-container class="mx-auto" id="content-mobile-all">
        <div class="text-sec-5 mt-4 mt-lg-0">
          <div class="text-content">
            <span class="title">Contenidos para todos
            </span>
            <br>
            <br>
            <span class="content">Tenemos amplia variedad de contenidos de todo tipo: <br>Infantiles, familiares, adultos, deportes, etc</span>
          </div>
        </div>
      </b-container>
      <b-row class="mx-lg-2 mt-lg-5 mx-2">
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-cine"><span class="img-text">Películas</span></div>
        </b-col>
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-deportes"><span class="img-text">Deportes</span></div>
        </b-col>
      </b-row>
      <b-row class="mx-lg-2 mt-lg-4 mx-2">
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-infantil"><span class="img-text">Infantiles</span></div>
        </b-col>
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-nacionales"><span class="img-text">Nacionales</span></div>
        </b-col>
      </b-row>
      <b-row class="mx-lg-2 mt-lg-4 mx-2">
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-noticias"><span class="img-text">Noticias</span></div>
        </b-col>
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-cocina"><span class="img-text">Cocina</span></div>
        </b-col>
      </b-row>
      <b-row class="mx-lg-2 mt-lg-4 mx-2">
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-musicales"><span class="img-text">Musicales</span></div>
        </b-col>
        <b-col lg="6" sm="12" class="mt-4 mt-lg-0">
          <div class="content-img img-radios"><span class="img-text">Radios</span></div>
        </b-col>
      </b-row>
      <b-container class="mx-lg-auto mt-lg-5 pt-lg-5 pb-lg-5">
        <div class="text-sec-5 mt-4 mt-lg-0">
          <span class="title title-big"><b>Métodos de Pago</b></span>
        </div>
        <div class="text-sec-5-mini mx-auto mt-lg-2 justify-content-center mb-5 mb-lg-0">
          Tarjetas de crédito y débito <br>
          <b-container class="mt-lg-4">
            <b-row class="justify-content-lg-center">
              <b-col>
                <img class="mt-lg-1 mt-1" src="~@/assets/cred_cards.png" height="90px" width="350px">
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-container>
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'Home',
  components: {

  },
  methods: {
    handleScroll: function (){
      /* eslint-disable */
      if(window.scrollY == 0){
        document.querySelector("#main-nav").classList.remove("scrolled");
      }else{
        document.querySelector("#main-nav").classList.add("scrolled");
      }
      
      //console.info(window.scrollY);
      /* eslint-enable */
    },
    handleIndivPlanButton(){
      window.location.href = 'https://pago.redcristianadigital.com/index.php?route=product/product&path=59&product_id=53';
    },
    handleDuoPlanButton(){
      window.location.href = 'https://pago.redcristianadigital.com/index.php?route=product/product&path=59&product_id=54';
    },
    handleFamPlanButton(){
      window.location.href = 'https://pago.redcristianadigital.com/index.php?route=product/product&path=59&product_id=55';
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function(){
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style>
html{
  scroll-behavior: smooth;
}
body,html{
  overflow-x: hidden !important;
}

#helpmodal{
  padding: 0 8px !important;
}

#helpmodal .modal-dialog {
  position: relative !important;
  margin: 1.75rem auto !important;
}

#helpmodal .modal-body {
  flex-direction: column !important;
  padding: 1rem 2rem;
}

#helpmodal .modal-header{
  padding: 5px 10px 0 0;
  border-bottom: 0;
}

.modal-open{
  padding-right: 0 !important;
}

#main-nav{
  background-color: transparent !important;
  position: fixed;
  top: 0;
  transition: background-color 200ms linear;
  width: 100%;
  padding-right: 16px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media(max-width: 3000px){

  #help-quest{
    display: none !important;
  }

.btn-login{
  background-color: transparent !important;
  border-radius: 1.25rem !important;
  border-color: white !important;
  color: white !important;
  border-width: 2px !important;
}
.btn-secondary.btn-green{
  font-size: 1rem !important;
  background-color: #08B051 !important;
  border-color: #08B051;
  color: white;
  border-radius: 1.25rem;
  border-width: 3px;
}

  .navbar-brand{
    margin-right: 1rem !important;
  }
  .title-big{
    font-size: 2.5rem !important;
  }
  .desktop-only{
    display: block !important;
  }
  .img-tv-title{
    color: white;
    font-weight: bold;
    font-size: 2.3rem;
    margin-right: 4rem;
    font-family: 'Ceraround-pro', sans-serif;
  }
  .img-hdmi{
    height: 50px;
  }

  .text-movil{
    display: none;
  }

  .mid-text{
    font-size: 1.3rem;
    font-family: 'Ceraround-pro', sans-serif;
    font-weight: 700;
    color: black;
  }
  .col-lg-3-custom{
    max-width: 21% !important;
  }
  .price-text{
    font-size: 2rem;
  }
  .simple-text{
    font-weight: normal !important;
  }
  b.title-bold{
    font-weight: 600;
  }
  .navbar-brand img{
    height: 50px;
  }
  .mt-sm-5{
    margin-top: unset;
  }

  .basic-plan{
    background-image: url(~@/assets/plan-basic-v2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25%;
    height: 320px;
  }

  .azteca-pack{
    background-image: url(~@/assets/pack-azteca.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25%;
    height: 320px;
  }

  .family-plan{
    background-image: url(~@/assets/plan-family-v2.png);
    background-position: 45% 0%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25%;
    height: 320px;
  }

  .venus-pack{
    background-image: url(~@/assets/pack-adulto.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 25%;
    height: 320px;
  }

  .img-tv{
    background-image: url(~@/assets/dispositivos.png);
    background-size: contain;
    height: 26rem;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
  }

  .text-nav{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.3rem;
  }
  .text-sec-1{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.5rem;
    line-height: normal !important;
  }
  .text-sec-2{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 2.5rem;
  }
  .text-sec-2-mini{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: .8rem;
    width: 60%;
  }
  .text-sec-2-desc{
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
  }
  .title{
    font-weight: 600;
  }
  .text-sec-1-mini{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.2rem;
  }
  .header{
    background-image: url(~@/assets/fondo-header.jpg);
    background-position: 100% 0%;
    /*background-size: 100% 70vh;*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 40rem;
  }
  #main-nav{
    background-color: transparent !important;
    position: fixed;
		top: 0;
    transition: background-color 200ms linear;
    width: 100%;
  }
  #terms-nav{
    background-color: rgb(0,0,0) !important;
    background-color: rgba(0,0,0, 1) !important;
    color: white;
    position: fixed;
    width: 100%;
    transition: background-color 200ms linear;
  }   
  #main-nav.scrolled{
    background-color: rgb(0,0,0) !important;
    background-color: rgba(0,0,0, 1) !important;
    color: white;
    transition: background-color 200ms linear;
  }
  .nav-link{
    color: rgba(255, 255, 255, 1) !important;
  }
  .pt-custom{
    padding-top: 7rem;
  }
  .promo-text b{
    font-size: 5rem;
    line-height: initial;
    font-weight: 500;
  }
  .onp-btn-b{
    width: 60%;
    height: unset;
  }
  .sec-2 .onp-btn-b{
    /* font-size: 1.1rem !important; */
    margin-right: .85rem !important;
  }

  .desktop-only .onp-btn-b-pack{
    width: 80%;
    height: unset;
  }
  .sec-2 .desktop-only .onp-btn-b-pack{
    width: 65%;
    font-size: .8rem !important;
  }

  #gray-mobile {
    display: none;
  }

  #gplay-sect-mobile{
    display: none;
  }

  #content-mobile-all{
    display: none;
  }

  #remember-mobile{
    display: none;
  }

  #remember-desktop{
    display: flex;
  }

  .whitebox{
    border-radius: 25px;
    border: #e0e0e0 solid 1px;
    background-color: white;
    width: 95%;
    height: unset;
  }
  .stb-img{
    background-image: url(~@/assets/stb.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
  .whitebox .text-sec-2-mini{
    font-size: .8rem;
    width: unset !important;
  }
  .bold{
    font-weight: bold;
  }
  .whitebox .t-custom-1{
    text-align: left;
    font-size: 1.2rem !important;
  }
  .info-sect .t-custom-1{
    text-align: left;

  }
  .text-sec-2-info{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.3rem;
    width: 100%;
  }

  .text-sec-2-info.info {
    display: flex;
  }

  .text-sec-2-info.info .info-remember-1 {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .text-sec-2-info.info .info-remember-2 {
    font-size: .9rem;
    line-height: 1.5rem;
  }

  .info-sect{
    width: 64% !important;
  }
  .dispo-sect{
    background-color: #F0F0F0;
    color: white;
    height: 600px;
  }
  .text-sec-3{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 2.5rem;
  }
  .text-sec-3-mini{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.2rem;
    width: 60%;
  }
  .dispo-box{
    background-color: white;
    border-radius: 5px;
    color: black;
    height: 110px;
    margin-left: unset;
    margin-right: unset;
  }
  .dispo-title{
    font-weight: 600;
    text-align: left;
    margin-left: 3rem;
    color: black;
    font-size: 2rem;
  }
  .dispo-list{
    margin-left: 3rem;
    text-align: left;
    color: black;
    font-size: 1rem;
  }
  .gplay-sect{
    background-color: #000;
    height: 860px;
  }

  .gplay-sect .gplay-sect-foot .foot-disp-1 {
    border-right: solid 1px white;
    padding: 0px 4px 1px 0;
    text-align: left;
    color: white;
    font-size: 3vh;
  }

  .foot-disp-1 b {
    font-size: 2.5vw;
  }

  .foot-disp-1 p {
    font-size: 1.3vw;
  }

  .gplay-sect .gplay-sect-foot .foot-disp-2 {
    display: flex;
    justify-content: space-between;
  }

  .foot-disp-2 .text-sec-4 {
    text-align: left;
    padding-left: 15px;
  }

  .text-sec-4 b{
    font-size: 2.5vw;
  }

  .text-sec-4 p {
    font-size: 1.3vw;
  }

  .phone-img{
    background-image: url(~@/assets/movil.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    width: 100%;
    height: 480px;
  }
  .text-sec-4{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.5rem;
  }
  .text-sec-4 .title{
    font-size: 3rem;
  }
  .text-sec-5{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.5rem;
  }
  .text-sec-5-mini{
    color: black;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 1.2rem;
  }
  .text-sec-5 .title{
    font-size: 1.6rem;
  }
  .content-img{
    width: 100%;
    /*height: 200px;*/
    height: 12rem;
    position: relative;
    overflow: hidden !important;
  }
  .img-cine{
    background-image: url(~@/assets/cine.jpg);
    background-size: cover;
  }
  .img-deportes{
    background-image: url(~@/assets/deportes.jpg);
    background-size: cover;
  }
  .img-infantil{
    background-image: url(~@/assets/infantiles.jpg);
    background-size: cover;
  }
  .img-nacionales{
    background-image: url(~@/assets/nacionales.jpg);
    background-size: cover;
  }
  .img-noticias{
    background-image: url(~@/assets/noticias.jpg);
    background-size: cover;
  }
  .img-cocina{
    background-image: url(~@/assets/cocina.jpg);
    background-size: cover;
  }
  .img-musicales{
    background-image: url(~@/assets/musicales.jpg);
    background-size: cover;
  }
  .img-radios{
    background-image: url(~@/assets/radios.jpg);
    background-size: cover;
  }
  .content-img span.img-text{
    color: white;
    font-family: 'Ceraround-pro', sans-serif;
    font-size: 2.8rem;
    font-weight: 500;
    position: absolute;
    left: 0;
    margin-left: 4rem;
    margin-top: 3.8rem;
  }
  .img-payment{
    background-image: url(~@/assets/logo-webpay.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 16%;
    height: 4rem;
  }
  .contact-footer{
    background-color: black;
    color: white;
  }
  .contact-footer .text-heading{
    font-weight: 600;
    text-align: left;
    font-family: 'Ceraround-pro', sans-serif;
  }
  .contact-footer .text-ordered{
    text-align: left;
    font-family: 'Ceraround-pro', sans-serif;
  }

  .text-ordered .foot-link {
    color: white;
  }
  .text-ordered #first-link {
    font-weight: bold;
  }

  .onp-blue{
    color: #1EB1E6;
    font-weight: 600;
  }
  .gpay-img{
    background-image: url(~@/assets/disponible-en-google-play-badge.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    width: 40%;
    height: 50px;
  }
  .onp-logo-img{
    background-image: url(~@/assets/logo-rcd-gris.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    width: 40%;
    height: 50px;
  }
}

@media(max-width: 990px) {

  #help-quest{
    display: block !important;
  }
  
.btn-login{
  background-color: transparent !important;
  border-radius: 1.25rem !important;
  border-color: white !important;
  color: white !important;
  border-width: 2px !important;
}

#helpmodal .btn-secondary.btn-green{
  font-size: 1rem !important;
}

.img-tv-title{
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: 'Ceraround-pro', sans-serif;
}
.img-hdmi{
  height: 50px;
}

.text-movil{
  display: flex;
  justify-content: center;
}

.mid-text{
  font-size: 1rem;
  font-family: 'Ceraround-pro', sans-serif;
  font-weight: 700;
  color: black;
}
.price-text{
  font-size: 2rem;
}
.simple-text{
  font-weight: normal !important;
}
b.title-bold{
  font-weight: 600;
}
.navbar-brand img{
  height: 30px;
}
.bi-info-circle{
  color: black !important;
}
#main-nav.navbarOpen{
  background-color: rgba(0,0,0, 1) !important;
  height: 100%;
}

.mt-sm-5{
  margin-top: 1rem;
}

.basic-plan{
  background-image: url(~@/assets/plan-basic-v2.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 330px;
  width: 100%;
}

.azteca-pack{
  background-image: url(~@/assets/pack-azteca.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 330px;
  width: 100%;
}

.family-plan{
  background-image: url(~@/assets/plan-family-v2.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 320px;
  width: 100%;
}

.venus-pack{
  background-image: url(~@/assets/pack-adulto.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 320px;
  width: 100%;
}

.img-tv{
  background-image: url(~@/assets/dispositivos.png);
  background-size: contain;
  background-position: 50% 0%;
  height: 40vw;
  width: 100%;
  background-repeat: no-repeat;
}
.text-nav{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.3rem;
}
.text-sec-1{
  display: none;
}

.text-sec-1-mini {
  text-align: center;
}

.text-movil {
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.3rem;
  text-align: center;
}

.gray-box div {
  display: column;
  justify-content: left;
}

span.mobile-only{
  font-family: 'Ceraround-pro', sans-serif;
  font-style: bold;
  
  text-align: left;
}

.text-sec-2{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.4rem;
}
.text-sec-2-mini{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
  width: 60%;
}

.text-sec-2-mini span {
  display: flex;
  justify-content: left;
}

.text-sec-2-desc{
  font-size: .8rem;
  font-weight: 500;
}

.mobile-only {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.whitebox-gray .mobile-only {
  display: flex;
  justify-content: left;
  margin-bottom: 0rem;
}

.whitebox-gray .mobile-only.only-1 {
  display: flex;
  justify-content: left;
  margin-bottom: 0rem;
  margin-left: 3rem;
}

span.mobile-only.sin {
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .5rem !important;
  width: 30vw;
}

.title{
  font-weight: 600;
  font-size: 3rem;
}
.text-sec-1-mini{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1rem;
}
.header{
  background-image: url(~@/assets/fondo-header.jpg);
  background-position: 40% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
#main-nav{
  background-color: transparent !important;
  position: fixed;
  top: 0;
  transition: background-color 200ms linear;
  width: 100%;
  padding-right: 16px !important;
}
#terms-nav{
  background-color: rgb(0,0,0) !important;
  background-color: rgba(0,0,0, 1) !important;
  color: white;
  transition: background-color 200ms linear;
}   
#main-nav.scrolled{
  background-color: rgb(0,0,0) !important;
  background-color: rgba(0,0,0, 1) !important;
  color: white;
  transition: background-color 200ms linear;
}
.nav-link{
  color: rgba(255, 255, 255, 1) !important;
}
.pt-custom{
  padding-top: 6rem;
}
.promo-text b{
  font-size: 3rem;
  line-height: initial;
  font-weight: 500;
}
.onp-btn-b{
  justify-content: center;
  display: flex;
}

.mobile-only .onp-btn-b{
  width: 35%;
  height: 10%;
}

.onp-btn-b-pack{
  width: 80%;
  height: 30%;
}
.onp-btn-b-2{
  width: 60%;
  height: unset;
}
.sec-2 .onp-btn-b{
  width: 50%;
  font-size: .8rem !important;
}

.sec-2 .onp-btn-b-pack{
  width: 50%;
  font-size: .8rem !important;
}

#gplay-sect-desktop{
  display: none;
}

#gplay-sect-mobile{
  display: flex;
  height: 100%;
}

#white {
  display: none;
}

#gray-mobile{
  display: flex;
}

#content-desktop-all {
  display: none;
}

#content-mobile-all{
  display: flex;
  /* align-items: column; */
  justify-content: center;
}

#remember-mobile{
  display: flex;
}

#remember-desktop{
  display: none;
}

.whitebox-gray{
  border-radius: 15px;
  border: #e0e0e0 solid 1px;
  background-color: #e0e0e0;
  width: 95%;
  height: 15rem;
}
.stb-img{
  background-image: url(~@/assets/stb.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 30%;
}
.whitebox .text-sec-2-mini{
  font-size: .8rem;
  width: unset !important;
}
.bold{
  font-weight: bold;
}
.whitebox .t-custom-1{
  text-align: left;
  font-size: 1rem !important;
}

.text-sec-2-info{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .55rem;
  width: 100%;
  margin-left: 2.5rem;
}
.info-sect{
  width: 100% !important;
}

.info-sect .mx-lg-3 {
  display: flex;
  justify-content: center; 
}

.dispo-sect{
  background-color: #F0F0F0;
  color: white;
  height: 100%;
}
.text-sec-3{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.2rem;
}
.text-sec-3 .title{
  font-weight: 500;
  font-size: 1.3rem;
}
.text-sec-3-mini{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .65rem;
  width: 100%;
}
.dispo-box{
  background-color: white;
  border-radius: 5px;
  color: black;
  height: 80px;
}
.dispo-title{
  font-family: 'Ceraround-pro', sans-serif;
  font-weight: 600;
  text-align: left;
  margin-left: 2rem;
  color: black;
  font-size: 1.5rem;
}
.dispo-list{
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
  margin-left: 2rem;
  text-align: left;
  color: black;
}
.gplay-sect{
  background-color: #000;
  height: 810px;
}
.phone-img{
  background-image: url(~@/assets/movil.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 300px;
}
.text-sec-4{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1rem;
  text-align: left;
  margin-top: 1rem;
}
.text-sec-4 .title{
  font-size: 3rem;
  line-height: normal;
}

.text-sec-4 .text1 {
  font-size: .9rem;
}

#gplay-sect-mobile .mobile-dispt {
  margin-left: 3rem;
}

#gplay-sect-mobile .size-text {
  font-size: 1rem;
}

#content-mobile-all .title {
  font-size: 2rem;
}
#content-mobile-all .content {
  font-size: 1rem;
}

.text-sec-5{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.5rem;
}
.text-sec-5 .title{
  font-size: 1.5rem;
}
.text-sec-5-mini{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
}
.content-img{
  width: 100%;
  height: 8rem;
  position: relative;
  overflow: hidden !important;
}
.img-cine{
  background-image: url(~@/assets/cine.jpg);
  background-size: cover;
}
.img-deportes{
  background-image: url(~@/assets/deportes.jpg);
  background-size: cover;
}
.img-infantil{
  background-image: url(~@/assets/infantiles.jpg);
  background-size: cover;
}
.img-nacionales{
  background-image: url(~@/assets/nacionales.jpg);
  background-size: cover;
}
.img-noticias{
  background-image: url(~@/assets/noticias.jpg);
  background-size: cover;
}
.img-cocina{
  background-image: url(~@/assets/cocina.jpg);
  background-size: cover;
}
.img-musicales{
  background-image: url(~@/assets/musicales.jpg);
  background-size: cover;
}
.img-radios{
  background-image: url(~@/assets/radios.jpg);
  background-size: cover;
}
.content-img span.img-text{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  position: absolute;
  left: 0;
  margin-left: 2rem;
  margin-top: 1.5rem;
}
.img-payment{
  background-image: url(~@/assets/logo-webpay.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 16%;
  height: 4rem;
}
.contact-footer{
  background-color: black;
  color: white;
}
.contact-footer .text-heading{
  font-weight: 600;
  text-align: left;
  font-family: 'Ceraround-pro', sans-serif;
}
.contact-footer .text-ordered{
  text-align: left;
  font-family: 'Ceraround-pro', sans-serif;
}
.text-ordered a{
  color: white;
}
.text-ordered a:hover{
  color: white !important;
}
.onp-blue{
  color: #1EB1E6;
  font-weight: 600;
}
.app-img{
  background-image: url(~@/assets/app_icn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 50px;
}
.gpay-img{
  background-image: url(~@/assets/disponible-en-google-play-badge.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 50px;
}
.onp-logo-img{
  background-image: url(~@/assets/logo-rcd-gris.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  width: 40%;
  height: 50px;
}

#main-nav .navbar-toggler{
  margin-right: 0 !important;
}

.navbar-brand{
  margin-right: 0 !important;
}

.desktop-only{
  display: none !important;
}

}

@media(max-width: 500px) {

  #help-quest{
    display: block !important;
  }
  
#image-space{
  display: none;
}

.btn-login{
  background-color: transparent !important;
  border-radius: 1.25rem !important;
  border-color: white !important;
  color: white !important;
  border-width: 2px !important;
}

#helpmodal .btn-secondary.btn-green{
  font-size: 1rem !important;
}

.img-tv-title{
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: 'Ceraround-pro', sans-serif;
}
.img-hdmi{
  height: 50px;
}

.text-movil{
  display: flex;
  justify-content: center;
}

.mid-text{
  font-size: 1rem;
  font-family: 'Ceraround-pro', sans-serif;
  font-weight: 700;
  color: black;
}
.price-text{
  font-size: 2rem;
}
.simple-text{
  font-weight: normal !important;
}
b.title-bold{
  font-weight: 600;
}
.navbar-brand img{
  height: 30px;
}
.bi-info-circle{
  color: black !important;
}
#main-nav.navbarOpen{
  background-color: rgba(0,0,0, 1) !important;
  height: 100%;
}

.mt-sm-5{
  margin-top: 1rem;
}

.basic-plan{
  background-image: url(~@/assets/plan-basic-v2.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 330px;
  width: 100%;
}

.azteca-pack{
  background-image: url(~@/assets/pack-azteca.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 100%;
}

.family-plan{
  background-image: url(~@/assets/plan-family-v2.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 320px;
  width: 100%;
}

.venus-pack{
  background-image: url(~@/assets/pack-adulto.png);
  background-position: 45% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
  width: 100%;
}

.img-tv{
  background-image: url(~@/assets/dispositivos.png);
  background-size: contain;
  background-position: 50% 0%;
  height: 40vw;
  width: 100%;
  background-repeat: no-repeat;
}
.text-nav{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.3rem;
}
.text-sec-1{
  display: none;
}

.text-sec-1-mini {
  text-align: center;
}

.text-movil {
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.1rem;
  text-align: center;
}

.gray-box div {
  display: column;
  justify-content: left;
}

span.mobile-only{
  font-family: 'Ceraround-pro', sans-serif;
  font-style: bold;
  
  text-align: left;
}

.text-sec-2{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  margin-top: 0;
  padding-top: 0;
}

.text-sec-2 b {
  font-size: 1.5rem;
  margin-top: 0;
  padding-top: 0;
}

.text-sec-2-info.info .info-remember-1 {
    font-size: 1.3rem;
    margin-right: 1rem;
  }

.text-sec-2-info.info .info-remember-2 {
  font-size: .5rem;
  line-height: .7rem;
}

.text-sec-2-mini{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
  width: 60%;
}

.text-sec-2-mini span {
  display: flex;
  justify-content: left;
}

.text-sec-2-desc{
  font-size: .75rem;
  font-weight: 500;
}

.mobile-only {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.whitebox-gray .mobile-only {
  display: flex;
  justify-content: left;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.whitebox-gray .gray-box .mobile-only.only-1 {
  display: flex;
  justify-content: left;
  margin-bottom: 0rem;
  margin-left: 0rem;
}

span.mobile-only.sin {
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1rem;
  width: 30vw;
}

.title{
  font-weight: 600;
  font-size: 3rem;
}
.text-sec-1-mini{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1rem;
}
.header{
  background-image: url(~@/assets/fondo-header.jpg);
  background-position: 40% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
#main-nav{
  background-color: transparent !important;
  position: fixed;
  top: 0;
  transition: background-color 200ms linear;
  width: 100%;
  padding-right: 16px !important;
}
#terms-nav{
  background-color: rgb(0,0,0) !important;
  background-color: rgba(0,0,0, 1) !important;
  color: white;
  transition: background-color 200ms linear;
}   
#main-nav.scrolled{
  background-color: rgb(0,0,0) !important;
  background-color: rgba(0,0,0, 1) !important;
  color: white;
  transition: background-color 200ms linear;
}
.nav-link{
  color: rgba(255, 255, 255, 1) !important;
}
.pt-custom{
  padding-top: 6rem;
}
.promo-text b{
  font-size: 3rem;
  line-height: initial;
  font-weight: 500;
}

.promo-text.plans{
  font-size: .85rem;
  line-height: initial;
  font-weight: 500;
}

.promo-text.cost{
  font-size: .5rem;
  line-height: initial;
  font-weight: 500;
}

.onp-btn-b{
  justify-content: center;
  display: flex;
}

.mobile-only .onp-btn-b{
  width: 70%;
  height: 10%;
}

.onp-btn-b-pack{
  width: 80%;
  height: 30%;
}
.onp-btn-b-2{
  width: 70%;
  height: unset;
}
.sec-2 .onp-btn-b{
  width: 50%;
  font-size: .8rem !important;
}

.sec-2 .onp-btn-b-pack{
  width: 50%;
  font-size: .8rem !important;
}

#gplay-sect-desktop{
  display: none;
}

#gplay-sect-mobile{
  display: flex;
  height: 100%;
}

#white {
  display: none;
}

#gray-mobile{
  display: flex;
}

#content-desktop-all {
  display: none;
}

#content-mobile-all{
  display: flex;
  /* align-items: column; */
  justify-content: center;
}

#remember-mobile{
  display: flex;
  align-items: center;
}

#remember-desktop{
  display: none;
}

.whitebox-gray{
  border-radius: 15px;
  border: #e0e0e0 solid 1px;
  background-color: #e0e0e0;
  width: 95%;
  height: 10rem;
}
.stb-img{
  background-image: url(~@/assets/stb.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 95px;
  width: 40%;
}
.whitebox .text-sec-2-mini{
  font-size: .8rem;
  width: unset !important;
}
.bold{
  font-weight: bold;
}
.gray-box .t-custom-1 .mobile-only.only-11 {
  /* text-align: left; */
  font-size: .8rem !important;
  width: 100%;
}

.gray-box .t-custom-1 .mobile-only.only-12 {
  /* text-align: left; */
  font-size: 1.2rem !important;
  width: 100%;
}

.text-sec-2-info{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .55rem;
  width: 100%;
  margin-left: 2.5rem;
}
.info-sect{
  width: 100% !important;
}

.info-sect .mx-lg-3 {
  display: flex;
  justify-content: center; 
}

.dispo-sect{
  background-color: #F0F0F0;
  color: white;
  height: 100%;
}
.text-sec-3{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.2rem;
}
.text-sec-3 .title{
  font-weight: 500;
}
.text-sec-3-mini{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .65rem;
  width: 100%;
}
.dispo-box{
  background-color: white;
  border-radius: 5px;
  color: black;
  height: 80px;
}
.dispo-title{
  font-family: 'Ceraround-pro', sans-serif;
  font-weight: 600;
  text-align: left;
  margin-left: 2rem;
  color: black;
  font-size: 1.5rem;
}
.dispo-list{
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
  margin-left: 2rem;
  text-align: left;
  color: black;
}
.gplay-sect{
  background-color: #000;
  height: 810px;
}
.phone-img{
  background-image: url(~@/assets/movil.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 300px;
}
.text-sec-4{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1rem;
  text-align: left;
  margin-top: 1rem;
}
.text-sec-4 .title{
  font-size: 3rem;
  line-height: normal;
}

.text-sec-4 .text1 {
  font-size: .9rem;
}

#gplay-sect-mobile .title {
  font-size: 1.7rem;
}

#gplay-sect-mobile .mobile-dispt {
  margin-left: 3rem;
}

#gplay-sect-mobile .size-text {
  font-size: .7rem;
}

#content-mobile-all .text-content {
  line-height: .7rem;
}

#content-mobile-all .title {
  font-size: 1.2rem;
}
#content-mobile-all .content {
  font-size: .7rem;
}

.text-sec-5{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 1.5rem;
}
.text-sec-5 .title{
  font-size: 1.5rem;
}
.text-sec-5-mini{
  color: black;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: .8rem;
}
.content-img{
  width: 100%;
  height: 8rem;
  position: relative;
  overflow: hidden !important;
}
.img-cine{
  background-image: url(~@/assets/cine.jpg);
  background-size: cover;
}
.img-deportes{
  background-image: url(~@/assets/deportes.jpg);
  background-size: cover;
}
.img-infantil{
  background-image: url(~@/assets/infantiles.jpg);
  background-size: cover;
}
.img-nacionales{
  background-image: url(~@/assets/nacionales.jpg);
  background-size: cover;
}
.img-noticias{
  background-image: url(~@/assets/noticias.jpg);
  background-size: cover;
}
.img-cocina{
  background-image: url(~@/assets/cocina.jpg);
  background-size: cover;
}
.img-musicales{
  background-image: url(~@/assets/musicales.jpg);
  background-size: cover;
}
.img-radios{
  background-image: url(~@/assets/radios.jpg);
  background-size: cover;
}
.content-img span.img-text{
  color: white;
  font-family: 'Ceraround-pro', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  position: absolute;
  left: 0;
  margin-left: 2rem;
  margin-top: 1.5rem;
}
.img-payment{
  background-image: url(~@/assets/logo-webpay.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 16%;
  height: 4rem;
}
.contact-footer{
  background-color: black;
  color: white;
}
.contact-footer .text-heading{
  font-weight: 600;
  text-align: left;
  font-family: 'Ceraround-pro', sans-serif;
}
.contact-footer .text-ordered{
  text-align: left;
  font-family: 'Ceraround-pro', sans-serif;
}
.text-ordered a{
  color: white;
}
.text-ordered a:hover{
  color: white !important;
}
.onp-blue{
  color: #1EB1E6;
  font-weight: 600;
}
.app-img{
  background-image: url(~@/assets/app_icn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 50px;
}
.gpay-img{
  background-image: url(~@/assets/disponible-en-google-play-badge.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  width: 100%;
  height: 50px;
}
.onp-logo-img{
  background-image: url(~@/assets/logo-rcd-gris.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  width: 40%;
  height: 50px;
}

#main-nav .navbar-toggler{
  margin-right: 0 !important;
}

.navbar-brand{
  margin-right: 0 !important;
}

.desktop-only{
  display: none !important;
}

}

</style>
